$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@font-face {
  font-family: 'serendicon';
  src: url('../font/serendicon.eot?94015422');
  src: url('../font/serendicon.eot?94015422#iefix') format('embedded-opentype'), url('../font/serendicon.woff2?94015422') format('woff2'),
    url('../font/serendicon.woff?94015422') format('woff'), url('../font/serendicon.ttf?94015422') format('truetype'),
    url('../font/serendicon.svg?94015422#serendicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'serendicon';
    src: url('../font/serendicon.svg?94015422#serendicon') format('svg');
  }
}
*/
[class^='si-']:before,
[class*=' si-']:before {
  font-family: 'serendicon';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.si-less:before {
  content: '\e800';
} /* '' */
.si-hyphen:before {
  content: '\e801';
} /* '' */
.si-plus:before {
  content: '\e802';
} /* '' */
.si-asterisk:before {
  content: '\e803';
} /* '' */
.si-greater:before {
  content: '\e80a';
} /* '' */
