// scss-docs-start main-nav
$headerHeight: 87px;
$navHeight: 87px;
// scss-docs-end main-nav

// scss-docs-start travel-program-block
$travel-extension-teaser-branded-list-top: 0.3rem;
// scss-docs-end travel-program-block

// scss-docs-start travel-pricing-block
$pricing-block-title-margin-bottom: 0;
$pricing-block-info-inner-padding: 5rem 0 5rem 0;
$pricing-block-info-inner-padding-print: 2rem 0 2rem 0;
$pricing-block-services-outer-padding: 5rem 0 0 0;
$pricing-block-services-outer-padding-print: 2rem 0 0 0;
// scss-docs-end travel-pricing-block

// scss-docs-start star-rating
$star-color: #eab945;
// scss-docs-end star-rating
