.card-title {
  line-height: 1;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  @include font-uppercase-bold;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

h3 {
  @include font-uppercase-bold;
}

.btn {
  @include font-uppercase;
}
